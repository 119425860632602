import clone from 'clone';
import { newNetwork } from './config';
import actions from './actions';

const initState = {
  networks: [],
  initialNetworks: false,
  currentNetwork: {},
  editableNetwork: {},
  isNewNetwork: false,
  enableEditView: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE_NETWORK: {
      const currentNetwork = action.network
        ? action.network
        : state.currentNetwork;
      return {
        ...state,
        networks: action.networks,
        currentNetwork: clone(currentNetwork),
        initialNetworks: true,
        isNewNetwork: false,
        enableEditView: false,
      };
    }
    case actions.SELECT_CURRENT_NETWORK: {
      const networks = state.networks;
      const index = networks.map(network => network.id).indexOf(action.id);
      const isNewNetwork = index === -1;
      const currentNetwork = isNewNetwork
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
            ...newNetwork,
          }
        : networks[index];
      const enableEditView = isNewNetwork;
      return {
        ...state,
        currentNetwork,
        isNewNetwork,
        enableEditView,
        editableNetwork: clone(currentNetwork),
      };
    }
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableNetwork: clone(state.currentNetwork),
      };
    case actions.UPDATE_EDIT_NETWORK:
      return {
        ...state,
        editableNetwork: clone(action.network),
      };
    default:
      return state;
  }
}
