const actions = {
  GET_NETWORK: 'GET_NETWORK',
  UPDATE_NETWORK: 'UPDATE_NETWORK',
  UPDATE_NETWORK_SAGA: 'UPDATE_NETWORK_SAGA',
  SELECT_CURRENT_NETWORK: 'SELECT_CURRENT_NETWORK',
  TOGGLE_VIEW: 'NETWORK_TOGGLE_VIEW',
  UPDATE_EDIT_NETWORK: 'NETWORK_UPDATE_EDIT_NETWORK',
  initData: () => ({ type: actions.GET_NETWORK }),
  deleteNetwork: selected => {
    return (dispatch, getState) => {
      const networks = getState().Networks.networks;
      const newNetworks = [];
      networks.forEach(network => {
        const selectedIndex = selected.indexOf(network.key);
        if (selectedIndex === -1) {
          newNetworks.push(network);
        }
      });
      dispatch({
        type: actions.UPDATE_NETWORK_SAGA,
        networks: newNetworks,
      });
    };
  },
  updateNetwork: network => {
    return (dispatch, getState) => {
      const networks = getState().Networks.networks;
      const index = networks.map(net => net.id).indexOf(network.id);
      if (index === -1) {
        networks.push(network);
      } else {
        networks[index] = network;
      }
      dispatch({
        type: actions.UPDATE_NETWORK_SAGA,
        networks,
        network,
      });
    };
  },
  selectCurrentNetwork: id => ({ type: actions.SELECT_CURRENT_NETWORK, id }),
  toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),
  editNetwork: network => ({ type: actions.UPDATE_EDIT_NETWORK, network }),
};
export default actions;
