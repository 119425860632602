const localDataName = 'mateNetwork';
const orderStatusOptions = ['Pending', 'Shipped', 'Delivered'];

const fakedata = [
  {
    key: 1,
    id: '1518713981654',
    name: 'Jowas', 
    description: 'Johula welfare group'   
  },
  {
    key: 2,
    id: '1518713981655',
    name: 'Snidero Wines',    
    description: 'Snidero Wines community'
  },
  {
    key: 3,
    id: '1518713981656',
    name: 'Rheingau Wine',    
    description: 'Rheingau Wines community'
  },
];

const newNetwork = {
  orderStatus: 'Pending',
  orderDate: new Date().getTime(),
  currency: '$',
  billTo: '',
  billToAddress: '',
  billFrom: '',
  billFromAddress: '',
  networkList: [
    {
      key: 1,
      itemName: '',
      costs: 0,
      qty: 0,
      price: 0,
    },
  ],
  subTotal: 0,
  vatRate: 10,
  vatPrice: 0,
  totalCost: 0,
};
const createDemoData = () => {
  const localData = localStorage.getItem(localDataName);
  if (localData) {
    try {
      const networks = JSON.parse(localData);
      if (networks && networks.length > 0) {
        return networks;
      }
    } catch (e) {}
  }
  return fakedata;
};

export {
  fakedata,
  createDemoData,
  localDataName,
  newNetwork,
  orderStatusOptions,
};
