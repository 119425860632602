import { all, takeEvery, put } from 'redux-saga/effects';
import { localDataName, createDemoData } from './config';
import actions from './actions';

export function* getNetwork() {
  yield put({
    type: actions.UPDATE_NETWORK,
    networks: createDemoData(),
  });
}
export function* updateNetworkSaga({ networks, network }) {
  yield localStorage.setItem(localDataName, JSON.stringify(networks));
  yield put({
    type: actions.UPDATE_NETWORK,
    networks,
    network,
  });
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_NETWORK, getNetwork),
    yield takeEvery(actions.UPDATE_NETWORK_SAGA, updateNetworkSaga),
  ]);
}
